import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CategoryQuestion}from './CategoryQuestion';
import RateSelection from './RateSelection';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Results from './Results';

const theme = createTheme({
  palette: {
    primary: {
      main: '#48E5C2',
    },
    secondary: {
      main: '#FFA07A',
    },
    error: {
      main: '#FF6B6B',
    },
    background: {
      default: '#F5F5F5',
    },
    inward: {
      main: '#B2DFDB',  // light teal
    },
    outward: {
      main: '#BBDEFB',  // light blue
    },
    forward: {
      main: '#FFCC80',  // light orange
    },
  },
  shape: {
    borderRadius: 8,
  }
});



export default function App() {

  const minSelection = 3; // Minimum number of items in Selection to proceed
  const [currentQuestion, setCurrentQuestion] = useState('question1');

  const initialContainers = {
    INWARD: [
      'Being grounded and mindful',
      'Bias and perception awareness',
      'Emotional Intelligence',
      'Leadership Identity',
      'Personal Productivity & flow',
      'Professional Vision',
      'Resilience',
      'Self – Confidence',
      'Stress management',
      'Time management',
    ],
    OUTWARD: [
      'Accountability',
      'Authentic Relationships',
      'Delegation',
      'Effective Communication',
      'Foster psychological safety & trust',
      'Inspiration',
      'Manage upwards',
      'Motivating others',
      'Navigate Conflict',
      'Team alignment',
    ],
    FORWARD: [
      'Agile Leadership',
      'Change Management',
      'Critical Thinking',
      'Influence',
      'Influencer engineering',
      'Lateral collaboration',
      'Purpose',
      'Role clarity & Planning for success',
      'Strategy execution',
      'Vision',
    ],
  };
  
  const colors = {
    INWARD: theme.palette.inward.main,
    OUTWARD: theme.palette.outward.main,
    FORWARD: theme.palette.forward.main,
  };
  
  
  const [containers, setContainers] = useState({
    INWARD: initialContainers.INWARD.map((content, index) => ({
      id: 'INWARD-' + index,
      content,
      color: colors.INWARD,
      originalContainer: 'INWARD',
      rating: 1,
    })),
    OUTWARD: initialContainers.OUTWARD.map((content, index) => ({
      id: 'OUTWARD-' + index,
      content,
      color: colors.OUTWARD,
      originalContainer: 'OUTWARD',
      rating: 1,
    })),
    FORWARD: initialContainers.FORWARD.map((content, index) => ({
      id: 'FORWARD-' + index,
      content,
      color: colors.FORWARD,
      originalContainer: 'FORWARD',
      rating: 1,
    })),
    Selection: [],
  });
  
  const canProceed = containers['Selection'].length >= minSelection;
  return (
    <ThemeProvider theme={theme}>
    <Box 
        component={Paper} 
        elevation={3}
        p={2} // padding: 16px
        maxWidth="50%"
        m="auto" // margin: auto
        bgcolor={theme.palette.background.default} // background color
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
    >
        <Typography variant="h5" mb={2} className="print-title">Priority Assessment</Typography>

        <Box flexGrow={1} width="100%">
            {currentQuestion === 'question1' && (
                <>
                    <CategoryQuestion containers={containers} setContainers={setContainers} />
                    {canProceed && (
                        <Box display="flex" justifyContent="flex-end" mt={2}>
                            <Button variant="contained" color="primary" onClick={() => setCurrentQuestion('question2')}>
                                Next
                            </Button>
                        </Box>
                    )}
                </>
            )}

            {currentQuestion === 'question2' && (
                <>
                    <RateSelection selection={containers['Selection']} setContainers={setContainers} />
                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <Button variant="contained" color="secondary" onClick={() => setCurrentQuestion('question1')}>
                            Back
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => setCurrentQuestion('results')}>
                            Next
                        </Button>
                    </Box>
                </>
            )}

            {currentQuestion === 'results' && (
                <>
                    <Results selections={containers['Selection']} />
                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <Button variant="contained" color="secondary" onClick={() => setCurrentQuestion('question2')}>
                            Back
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => window.print()}>
                            Print Results
                        </Button>
                    </Box>
                </>
            )}
        </Box>
      </Box>
    </ThemeProvider>


  );
}
