import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register required components
ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend,ChartDataLabels);

const Results = ({ selections }) => {
  // Prepare the data for the polar area chart

  const labels = selections.map((item) => item.content);
  const dataValues = selections.map((item) => item.rating);
  const backgroundColors = selections.map((item) => item.color);
  console.log(labels, dataValues, backgroundColors);

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Ratings',
        data: dataValues,
        backgroundColor: backgroundColors, // Make sure these are valid CSS color values
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      r: {
        grid: {
          display: true,
          drawOnChartArea: true,
          drawBorder: true,
          z: 1 // Setting a z-index value to appear above the shaded areas
        },
        angleLines: {
          display: true
        },
        min: 0,
        max: 10,
        ticks: {
          stepSize: 1,
          z: 1 // Setting a z-index value to appear above the shaded areas
        }
      }
    },
    plugins: {
      datalabels: {
        color: '#000', // Change this to black
        formatter: (value, context) => {
          return context.chart.data.labels[context.dataIndex];
        }
      },
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    }
  };
  
  



  return (
    <>
      <style>
        {`
        @media print {
          body * {
            visibility: hidden;
          }
          .results-component, .results-component * {
            visibility: visible;
          }
          .print-title, .print-title * {
            visibility: visible;
            position: relative; 
            padding-top: 20px; 
            text-align: center; 
            font-size: 24px;
            margin-bottom: 20px; // Spacing between title and results
            max-height: 50mm; // Ensuring the title doesn't occupy too much space
            overflow: hidden;
          }
          .results-component {
            width: 100%;
            max-width: 210mm;
            margin: auto;  // Centering the component horizontally
            height: auto;
            max-height: 247mm; // 297mm (A4) - 50mm (max title height)
            overflow: hidden;
          }
        }
      `}
      </style>
      <div className="results-component">
        <PolarArea data={data} options={options} />
      </div>

    </>
  );
};


export default Results;
