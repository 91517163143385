import React from 'react';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

const RateSelection = ({ selection, setContainers }) => {
  const handleSliderChange = (id, newValue) => {
    const newSelection = selection.map(item => {
      if (item.id === id) {
        return { ...item, rating: newValue };
      }
      return item;
    });
    setContainers(prevState => ({ ...prevState, Selection: newSelection }));
  };

  const marks = Array.from({ length: 10 }, (_, i) => ({
    value: i + 1,
  }));

  return (
    <div>
      <Typography variant="h6">Rate your selection</Typography>
      {selection.map(({ id, content, color, rating }) => (
        <div key={id} style={{ marginBottom: '16px', width: '100%' }}>
          <Typography variant="body1" style={{ color }}>
            {content}
          </Typography>
          <Slider
            value={rating}
            onChange={(e, newValue) => handleSliderChange(id, newValue)}
            valueLabelDisplay="auto"
            marks={marks}
            min={1}
            max={10}
            step={1}
            sx={{ width: '100%', color }}
          />
        </div>
      ))}
    </div>
  );
};

export default RateSelection;
