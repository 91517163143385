import React, { useState } from 'react';
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const CategoryQuestion = ({containers, setContainers}) => {

  const sensors = useSensors(
    useSensor(PointerSensor),
  );
  const MAX_ITEMS = 8

  const handleDragEnd = (event) => {
    const { active, over } = event;
  
    if (over) {
      const fromContainerId = active.id.split('-')[0];
      let toContainerId = over.id.split('-')[0];
  
      // Check if moving to Selection and if Selection is not full
      if (toContainerId === 'Selection' && containers['Selection'].length < MAX_ITEMS) {
        // Allow move
      } else if (fromContainerId === 'Selection' && toContainerId !== 'Selection') {
        // Move back to original category
        toContainerId = containers[fromContainerId].find((item) => `${fromContainerId}-${item.id}` === active.id).originalContainer;
      } else {
        // Invalid move, return
        return;
      }
  
      const oldIndex = containers[fromContainerId].findIndex((item) => `${fromContainerId}-${item.id}` === active.id);
      const newIndex = containers[toContainerId].findIndex((item) => `${toContainerId}-${item.id}` === over.id);
  
      const [movedItem] = containers[fromContainerId].splice(oldIndex, 1);
      containers[toContainerId].splice(newIndex, 0, movedItem);
  
      setContainers({
        ...containers,
        [fromContainerId]: [...containers[fromContainerId]],
        [toContainerId]: [...containers[toContainerId]],
      });
    }
  };
  

  return (
    <DndContext
    sensors={sensors}
    collisionDetection={closestCenter}
    onDragEnd={handleDragEnd}
  >
    <SortableContext
      items={Object.keys(containers).flatMap((key) => containers[key].length > 0 ? containers[key].map((item) => `${key}-${item.id}`) : [`${key}-dummy`])}
      strategy={verticalListSortingStrategy}
    >
      <Box display="flex" flexDirection="column" alignItems="center"> {/* Main Box */}
        <Box display="flex" justifyContent="space-between" mb={4}> {/* Box for A, B, C */}
          {['INWARD', 'OUTWARD', 'FORWARD'].map((containerId) => (
            <div key={containerId} style={{ margin: '0 15px', border: '1px solid gray', borderRadius: '8px', padding: '10px' }}>
              <Typography variant="h6" align='center'>{containerId}</Typography>
              {containers[containerId].map(({ id, content, color }) => (
                <SortableItem key={id} id={`${containerId}-${id}`} content={content} color={color} />
              ))}
            </div>
          ))}
        </Box>
        <Box mt={4} style={{border: '1px solid gray', borderRadius: '8px', padding: '10px'}}> {/* Box for Selection */}
          <Typography variant="h6">Selection</Typography>
          {containers['Selection'].length === 0 ? (
            <SortableItem key="dummy" id={`Selection-dummy`} content="" color="transparent" />
          ) : (
            containers['Selection'].map(({ id, content, color }) => (
              <SortableItem key={id} id={`Selection-${id}`} content={content} color={color} />
            ))
          )}
        </Box>
      </Box>
    </SortableContext>
  </DndContext>

  );
}

